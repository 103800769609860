import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Page, Metadata } from '~/components/Layout'
import PageSection from '~/sections/PageSection'
import useProductContext, {
  ProductContextProvider
} from '~/hooks/components/use-product-context'
import 'react-glidejs/dist/index.css'
import 'react-accessible-accordion/dist/fancy-example.css'

const PageChildren = ({ sections }) => {
  const context = useProductContext()
  const { currentVariant, selectVariant } = context

  return (
    <>
      {sections &&
        sections.map(section => (
          <PageSection
            key={section.id}
            section={section}
            currentVariant={currentVariant}
            selectVariant={selectVariant}
          />
        ))}
    </>
  )
}

const DefaultPageTemplate = ({ data: { page } }) => {
  const { metadata, sections, productContext, secondaryProductContext } = page

  return (
    <Page>
      <Metadata
        title={metadata && metadata.title}
        description={metadata && metadata.description.description}
        image={metadata && metadata.image.file.url}
        canonical={metadata && metadata.canonical}
        blockSearchIndexing={metadata && metadata.blockSearchIndexing}
      />
      {productContext ? (
        <ProductContextProvider
          product={productContext}
          secondaryProduct={secondaryProductContext}
        >
          <PageChildren sections={sections} />
        </ProductContextProvider>
      ) : (
        <PageChildren sections={sections} />
      )}
    </Page>
  )
}

export default DefaultPageTemplate

export const pageQuery = graphql`
  query DefaultPageQuery($slug: String!, $locale: String) {
    page: contentfulPage(slug: { eq: $slug }, node_locale: { eq: $locale }) {
      ...PageFragment
    }
  }
`

PageChildren.propTypes = {
  sections: PropTypes.arrayOf(PropTypes.shape({}))
}

DefaultPageTemplate.propTypes = {
  data: PropTypes.shape({
    page: PropTypes.shape({
      metadata: PropTypes.shape({
        blockSearchIndexing: PropTypes.bool,
        title: PropTypes.string,
        canonical: PropTypes.shape({
          __typename: PropTypes.string,
          slug: PropTypes.string
        }),
        description: PropTypes.shape({
          description: PropTypes.string
        }),
        image: PropTypes.shape({
          file: PropTypes.shape({
            url: PropTypes.string
          })
        })
      }),
      productContext: PropTypes.shape({}),
      secondaryProductContext: PropTypes.shape({}),
      sections: PropTypes.arrayOf(PropTypes.shape({}))
    })
  })
}
